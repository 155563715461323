@import 'src/styles/main';

/* Add a black background color to the top navigation */
.NavBar {
  background-color: #333;
  color: #f2f2f2;
  font-size: 16rem;
  display: flex;
  justify-content: space-between;
  position: fixed;
  top: 0;
  width: 100%;
  transition: top 0.3s;
  /* overflow: hidden; */
  z-index: 999;
}

.NavBar--scroll {
  top: -200px;
}

/* When the screen is less than 600 pixels wide, hide all links, except for the first one ("Home"). Show the link that contains should open and close the topnav (.icon) */
/* The "responsive" class is added to the topnav with JavaScript when the user clicks on the icon. This class makes the topnav look good on small screens (display the links vertically instead of horizontally) */
@media screen and #{$break1} {
  .NavBar{
    display: block;
  }
  .NavBar__icon {
    position: absolute;
    right: 0;
    top: 0;
    float: right;
    display: block;
    font-size: 1em;
    margin: 0.875em 1em;
  }
  .NavBar__icon--white {
    background-color: white;
  }
}
