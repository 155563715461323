@import 'src/styles/main';

.Menu {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}

@media screen and #{$break1} {
  .Menu--clicked {
    flex-direction: column;
  }
}
