@import 'src/styles/main';

.app__body {
  // background-color: rgb(236, 240, 245);
  margin-top: 4em;
  // margin-bottom: 1em;
  padding: 1em 1em;
}

@media #{$break2} {
  .app {
    height: 100vh;
    display: flex;
    flex-direction: column;
  }
  .app__body {
    flex-grow: 1;
  }  
}