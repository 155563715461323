@keyframes react-loading-skeleton {
    100% {
        transform: translateX(100%);
    }
}

@mixin skeleton-loader {
    --base-color: #ebebeb;
    --highlight-color: #f5f5f5;
    --animation-duration: 1.5s;
    --animation-direction: normal;
    --pseudo-element-display: block; /* Enable animation */

    color: var(--base-color);
    background-color: var(--base-color);
    // background-image: url("https://blog-d518e.web.app/270-ring.svg");
    background-image: url("data:image/svg+xml,%3Csvg width='100' height='100' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='Layer_1'%3E%3Ctitle%3ELayer 1%3C/title%3E%3Crect id='svg_3' height='100%25' width='100%25' y='0' x='0' stroke='%23ebebeb' fill='%23ebebeb'/%3E%3C/g%3E%3C/svg%3E");
    background-size: 100% 100%;

    // height: 300px;
    // width: 300px;
    // border-radius: 0.25rem;
    display: block;
    // line-height: 1;

    position: relative;
    overflow: hidden;
    z-index: 1; /* Necessary for overflow: hidden to work correctly in Safari */
}

@mixin skeleton-loader-after {
    content: ' ';
    display: var(--pseudo-element-display);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    // height: 300px;
    // width: 300px;
    height: 100%;
    background-repeat: no-repeat;
    background-image: linear-gradient(
        90deg,
        var(--base-color),
        var(--highlight-color),
        var(--base-color)
    );
    // background-image: url("https://blog-d518e.web.app/270-ring.svg");
    // background-size: 100% 100%;
    transform: translateX(-100%);

    animation-name: react-loading-skeleton;
    animation-direction: var(--animation-direction);
    animation-duration: var(--animation-duration);
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
}

// .skeleton-loader:empty {
//   width: 100%;
//   height: 100%;
//   display: block;
//   background: linear-gradient(
//       to right,
//       rgba(255, 255, 255, 0),
//       rgba(255, 255, 255, 0.5) 50%,
//       rgba(255, 255, 255, 0) 80%
//     ),
//     lightgray;
//   background-repeat: repeat-y;
//   background-size: 50px 500px;
//   background-position: 0 0;
//   animation: shine 1s infinite;
// }

// @keyframes shine {
//   to {
//     background-position: 100% 0;
//   }
// }